import React, { useState } from 'react';
import ServiceFooter from '../../components/ServiceFooter';
import Layout from '../../components/layout';
import { Col, Row } from 'react-bootstrap';
import { graphql } from 'gatsby';
import { imagePathURL } from "../../utils/JSONData";
import dotImg from '../../Assets/images/Home/dotImg.png';
import Pulse from 'react-reveal/Pulse';
import Fade from 'react-reveal/Fade';
import "../../Assets/styles/services.scss";
import { stacks } from '../../Assets/images/stack';
import Scrolltop from '../../components/Scrolltotop/scrolltop';
import Footer from '../../components/Footer/Footer';

const Reactnative = ({ data }) => {

  const reactnativetechnologyStack = [
    {
      id: 0,
      title: 'Backend',
      image: 'https://admin.madhavtechno.com/uploads/Backend_a781633728.png',
      dataArr: [
        { name: '.Net', image: stacks.Net },
        { name: 'PHP', image: stacks.PHP },
        { name: 'Java', image: stacks.Java },
        { name: 'Node', image: stacks.Node },
        { name: 'Ruby on Rails', image: stacks.RubyonRails }
      ]
    },
    {
      id: 1,
      title: 'Others',
      image: 'https://admin.madhavtechno.com/uploads/Others_2f8e35a9b8.png',
      // dataArr: ['Trello', 'RESTful API', 'BLE', 'Jira', 'Socket.IO', 'Slack', 'Redux', 'GraphQL', 'Third Party API/SDK Integration']
      dataArr: [
        { name: 'Trello', image: stacks.Trello },
        { name: 'RESTful API', image: stacks.restapi },
        { name: 'Jira', image: stacks.Jira },
        { name: 'BLE', image: stacks.BLE },
        { name: 'Socket.IO', image: stacks.SocketIO },
        { name: 'Slack', image: stacks.Slack },
        { name: 'GraphQL', image: stacks.GraphQL },
        { name: 'Redux', image: stacks.Redux }
      ]
    },
    {
      id: 2,
      title: 'Front-end',
      image: 'https://admin.madhavtechno.com/uploads/Frontend_08a71e145a.png',
      dataArr: [
        { name: 'React Native Elements', image: stacks.reactnativeelement},
        { name: 'NativeBase', image: stacks.nativebase },
        { name: 'Material-UI', image: stacks.materialui }
      ]
    },
    {
      id: 3,
      title: 'Testing',
      image: 'https://admin.madhavtechno.com/uploads/Testing_7725967b2e.png',
      dataArr: [
        { name: 'Appium', image: stacks.appium },
        { name: 'BrowserStack', image: stacks.BrowserStack },
        { name: 'Katalon Test Studio', image: stacks.Katalon }
      ]
    },
    {
      id: 4,
      title: 'Database',
      image: 'https://admin.madhavtechno.com/uploads/Database_de87ff3bcc.png',
      dataArr: [
        { name: 'SQlite', image: stacks.SQlite },
        { name: 'CoreData', image: stacks.CoreData },
        { name: 'Realm', image: stacks.Realm },
        { name: 'Firebase', image: stacks.firebase },
        { name: 'MySQL', image: stacks.MySQL },
      ]
    },
    {
      id: 5,
      title: 'Cloud & DevOps',
      image: 'https://admin.madhavtechno.com/uploads/Clouddevops_d86784a818.png',
      dataArr: [
        { name: 'Enterprise Distribution', image: stacks.AWS },
        { name: 'Google Cloud', image: stacks.GoogleCloud },
        { name: 'GitHub Action', image: stacks.Azure },
        { name: 'AWS', image: stacks.Docker },
        { name: 'CI/CD', image: stacks.CICD }
      ]
    },

  ]

  const { allServices, allServiceDevelopmentServices, allTechnologyStacks, allGetInTouchs } = data

  const [selectedStack, setSelectedStack] = useState(0);

  const handleStackClick = (index) => {
    setSelectedStack(index);
  };

  return (
    <>
      <Layout>
        <div className="service_react_group">
          <Row>
            <Col sm={12} md={8} className="m-auto">
              <div>
                <div className="service_react_title">
                  {allServices.nodes[0].data.attributes.title}
                </div>
                <div className="service_react_subtitle">
                  {allServices.nodes[0].data.attributes.description1}
                </div>
                <div className="service_react_subtitle2">
                  {allServices.nodes[0].data.attributes.description2}
                </div>
                <div className="service_react_button">
                  <button className="service_freequote">GET A FREE QUOTE</button>
                  <button className="service_ourwork">OUR WORK</button>
                </div>
              </div>
            </Col>
            <Col sm={12} md={4}>
              <div>
                <Pulse>
                  <img src={imagePathURL + allServices.nodes[0].data.attributes.image.data.attributes.url} alt="react-logo" />
                </Pulse>
              </div>
            </Col>
          </Row>
        </div>
        <div className="position-relative">
          <div className="service_react_title px-4 text-center">
            {/* {allServices.nodes[0].data.attributes.section1Title} */}
            React Native App Development Services
          </div>
          <div className="service_react_subtitle px-4 text-center">
            {/* {allServices.nodes[0].data.attributes.section1Desc} */}
            Grow your business with tailor-made and quality-driven React Native App Development Services
          </div>
          <div className="service_cross-platform">Cross Platform</div>
          <img src={dotImg} alt="dot" className="service_dot-image" />
          <div className="service_RN_develop">
            {allServiceDevelopmentServices.nodes[0].data.map((item, i) => {
              return (
                <Fade top>
                  <div className="service_develop_group" key={i}>
                    <img className="service_developimg" src={imagePathURL + item?.attributes.image.data.attributes.url} alt="searvice" />
                    <div className="service_developtitle">{item.attributes.title}</div>
                    <div className="service_developdetails">{item.attributes?.description}</div>
                  </div>
                </Fade>
              );
            })}
          </div>
        </div>
        <div className="service_quote_group">
          <div className="service_title_group">
            <div className="service_quote_title">
              {allGetInTouchs.nodes[0].data.attributes.title}
            </div>
            <div className="service_quote_details">
              {allGetInTouchs.nodes[0].data.attributes.description}
            </div>
          </div>
          <div>
            <button className="service_quote_button">
              {allGetInTouchs.nodes[0].data.attributes.btnTxt}
            </button>
          </div>
        </div>
        <div className="service_trofi_content">
          <div className="service_react_title text-center mx-2">
            {allServices.nodes[0].data.attributes.section2Title}
          </div>
          <div
            className="service_react_subtitle text-center mx-2"
          >
            {allServices.nodes[0].data.attributes.section2Subtitle}
          </div>
          <div className="service_trofi_subcontent">
            <Row>
              <Col sm={12} md={6} className="m-auto">
                <div className="service_trofi_title_content">
                  <div className="service_trofi_title">
                    {allServices.nodes[0].data.attributes.section2ContentTitle}
                  </div>
                  <div className="service_developdetails">
                    {allServices.nodes[0].data.attributes.section2ContentSubTitle}
                  </div>
                  <div className="service_trofi_details">
                    {allServices.nodes[0].data.attributes.section2ContentDesc}
                  </div>
                  <Fade top>
                    <button className="service_viewCaseStudy"> {allServices.nodes[0].data.attributes.section2ContentBtnTxt}</button>
                  </Fade>
                </div>
              </Col>
              <Col sm={12} md={6} className="m-auto text-center">
                <div>
                  <Pulse>
                    <img
                      className="service_trofiDevice_img"
                      src={imagePathURL + allServices.nodes[0].data.attributes.section2ContentImage.data.attributes.url}
                      alt="trofi"
                    />
                  </Pulse>
                </div>
              </Col>
            </Row>
          </div>
          <div className="service_react_title text-center m-auto">
            <span style={{ display: "inline-block" }}>

              Technology Stack
              <div className='text-item' style={{ display: "flex", justifyContent: "center", padding: '5px 0 29px 0' }} >
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "25%", marginRight: "10px" }}></div>
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "3%" }}></div>
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "25%", marginLeft: "10px" }}> </div>
              </div>
            </span>
          </div>
          {/* <div>
            <div className="service_line1"></div>
          </div> */}
          {/* <div className="service_stack_content">
            {allTechnologyStacks.nodes[0].data.map((item, i) => {
              return (
                <Fade top>
                  <div className="service_stack_group" key={i}>
                    <div className="service_stack_title_content">
                      <img src={imagePathURL + item.attributes?.image.data.attributes.url} alt="technology" />
                      <div className="service_stack_title">{item.attributes.title}</div>
                    </div>
                    <div className="service_arr_group">
                      {item.attributes.technologyName?.replace(/\[|\]/g, '').split(',').map((d, i) => {
                        return (
                          <div
                            className="service_quote_details"
                            style={{
                              margin: "auto",
                              display: "flex",
                              alignItems: "center",
                              fontSize: '14px'
                            }}
                            key={i}
                          >
                            <div className="service_bullets"></div>
                            {d}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Fade>
              );
            })}
          </div> */}
          <div className='technology'>
            <div className='technologytab'>
              {reactnativetechnologyStack.map((stack, index) => (
                <ul className={`tabbar-ul ${selectedStack === index ? 'active' : ''}`} key={index}>
                  <li
                    className='tabbar-li'
                    onClick={() => handleStackClick(index)}
                  >
                    {stack.title}
                  </li>
                </ul>
              ))}
            </div>
            <hr />
            <div className='technologydetalis'>
              {/* {selectedStack && ( */}
              <div className='stackdata'>
                {reactnativetechnologyStack[selectedStack].dataArr.map((item, index) => (
                  <ul>
                    {/* <img src="https://admin.madhavtechno.com/uploads/Maintenance_48c82ee210.png" /> */}
                    <img src={item.image} alt={selectedStack.title} style={{
                      height: item.name === 'Katalon Test Studio' ? '75px' : item.name === 'NativeBase' ? '75px' : '',
                      width: item.name === 'Katalon Test Studio' ? '150px' : item.name === 'NativeBase' ? '150px' : '',
                    }} />
                    <li key={index}>{item.name}</li>
                  </ul>
                ))}
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
        {/* <ServiceFooter /> */}
        <Footer />
        <Scrolltop />
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    allServices {
      nodes {
        data {
          attributes {
            description1
            description2
            image {
              data {
                attributes {
                  url
                }
              }
            }
            section1Desc
            section1Title
            section2ContentBtnTxt
            section2ContentDesc
            section2ContentImage {
              data {
                attributes {
                  url
                }
              }
            }
            section2ContentSubTitle
            section2ContentTitle
            section2Subtitle
            section2Title
            title
          }
        }
      }
    }

    allServiceDevelopmentServices {
      nodes {
        data {
          attributes {
            description
            title
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }

    allTechnologyStacks {
      nodes {
        data {
          attributes {
            technologyName
            title
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }

    allGetInTouchs {
      nodes {
        data {
          attributes {
            title
            description
            btnTxt
          }
        }
      }
    }
  }
`;

export default Reactnative